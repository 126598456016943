@use 'mixins' as *;

body {
  .main-container {
    @include set-main-padding(desktop);
    width: 100%;

    .main-container {
      padding: 0;
    }
  }

  .content-container {
    width: 100%;

    &:not(.margin-bottom-less) {
      margin-bottom: margin(container);
    }

    &:not(.spaceless) {
      @include set-content-padding(desktop);
    }

    &>*:not(.under-column) {
      &:not(.padding-less) {
        @include space(padding);
      }

      display: flex;

      &:not(.transparent) {
        @include set-background-color(default);
      }
    }

    &>.under-column {
      @include flex-column();
      gap: margin(container);

      &>*:not(.message),
      &>*:not(:empty) {
        &:not([class*=padding]) {
          @include space(padding);
        }

        @include set-background-color(default);
      }
    }

    &>.space-default {
      @include space(margin-bottom);
    }
  }

  .full-screen-container {
    @include full-size-important();
    @include set-z-index(popup);
    @include set-background-color(default);
    @include absolute((left: 0, top: 0));

    .centered {
      @include flex-column();
      align-items: center;
      width: 100%;
    }
  }

  .page-container {
    @include flex-column();
    min-height: 100vh;

    .page-content {
      flex: 1;
      $height: 0.1rem;

      .under-header {
        margin-top: calc(height(header, default) - $height);
        @include set-background-color(header);
        min-height: $height;
      }

      &>.page-section {
        margin-top: margin(container);
        margin-bottom: margin(container);
        display: block;
        // !important removed
      }

      &-column {
        @include flex-column();
        gap: padding(default);
      }

      &:not(.page-narrow) {

        .main-container,
        .content-container {
          @include set-max-width(page, default);
        }
      }

      &.page-narrow {
        &>:not(.page-default) {

          .main-container,
          .content-container {
            @include set-max-width(page, narrow);
          }
        }

        &>.page-default {

          .main-container,
          .content-container {
            @include set-max-width(page, default);
          }
        }
      }
    }
  }

  .sidebar-carousel-images-container {
    @include set-max-height(sidebarPhoto);
    @include set-min-height(room, photo, mobile);
    @include relative((height: 100%));
    overflow: hidden;
  }

  .sidebar-scroll-container {
    @include flex-column();
    @include space(padding-right);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .card-options-container {
    //@include hide-scrollbar();
    height: (margin(container)) + height(option, card);
    align-items: flex-end;
    overflow-y: hidden;
  }
}

@include max-tablet {
  body {
    .main-container {
      @include set-main-padding(mobile);
    }

    .content-container {
      @include set-content-padding(mobile);

      &>.under-column {
        .message {
          @include set-main-padding(mobile);
        }
      }
    }
  }
}

@include max-ipod {
  .page-container {
    .page-content {
      &.with-rate-plan-type-switcher {
        .under-header {
          margin-top: calc(height(header, withSwitcher) - 0.1rem);
        }
      }
    }
  }
}