@use 'mixins' as *;

body {

  .not-found {
    @include text(1, 1.375, 600);
    @include set-color(notFound);
    text-transform: uppercase;
  }

  .big-not-found {
    .not-found {
      @include text(1.25, 2, 600);
    }
  }

  .pricing-details {
    @include flex(space-between, flex-end);

    .price-format {
      @include set-color(textInactive);
      text-transform: uppercase;

      &:empty {
        display: none;
      }
    }

    .big {
      .header {
        &-bold {
          @include text(0.875, 1.25, 600);
        }

        &-default {
          @include text(0.625, 0.875);
        }
      }

      .price {
        &-base {
          @include set-color(basePrice);
          @include text(1, 1.1875);
        }

        &-base-top {
          @include set-color(basePrice);
          @include text(1, 0);
          @include absolute((top: 0));
        }

        &-final {
          @include set-color(finalPrice);
          @include text(1.5, 1.75, 600);
        }

        &-format {
          @include text(0.75, 1, 500);
        }
      }
    }

    .default,
    .small {
      .header {
        &-bold {
          @include text(0.75, 1, 600);
        }
      }

      .price {
        &-base {
          @include text(0.625, 0.875);
        }

        &-base-top {
          @include text(0.75, 0);
          @include absolute((top: 0));
        }

        &-final {
          @include text(1, 1.25, 600);
        }

        &-format {
          @include text(0.5, 1, 600);
        }
      }
    }

    .header {
      @include flex(flex-start);
      @include space(gap, .25);
      flex-wrap: wrap;

      &>*:not(:first-child) {
        float: left;
        text-align: left;
        white-space: nowrap;
      }
    }

    .price {
      @include flex(flex-start, baseline);
      position: relative;
      //flex-wrap: wrap;

      &-base,
      &-base-top {
        @include set-color(textInactive);
        @include space(margin-right, .25);
        text-decoration: line-through;
      }

    }
  }
}

@include max-tablet {
  body {
    .pricing-details {

      .default,
      .small {
        .header {
          &-bold {
            @include text(0.625, 0.875, 600);
          }
        }

        .price {
          &-base {
            @include text(0.625, 0.75);
          }

          &-base-top {
            @include text(0.75, 0);
            @include absolute((top: 0));
          }

          &-final {
            @include text(0.875, 1.125, 600);
          }
        }
      }
    }
  }
}

@include max-mobile {
  body {

    .big-not-found {
      .not-found {
        @include text(1, 2, 600);
      }
    }

    .pricing-details {
      .big {
        .header {
          &-bold {
            @include text(0.625, 1, 600);
          }
        }

        .price {
          &-base {
            @include text(0.75, 0.875);
          }

          &-base-top {
            @include text(0.75, 0);
            @include absolute((top: 0));
          }

          &-final {
            @include text(1.125, 1.25, 600);
          }

          &-format {
            @include text(0.6, 1, 500);
            width: 100%;
          }
        }
      }

      .default,
      .small {
        .price {
          &-format {
            width: 100%;
          }
        }
      }
    }
  }
}