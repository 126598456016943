@use 'mixins' as *;
@use 'setup' as *;
@use 'custom/scrollbar' as *;
@use 'custom/container' as *;
@use 'custom/visibility' as *;
@use 'custom/flex' as *;
@use 'custom/section' as *;
@use 'custom/text' as *;
@use 'custom/pricing' as *;
@use 'custom/various' as *;

/* You can add global styles to this file, and also import other style files */
html {
  height: 100%;
}

body {
  @include set-color(textPrimary);
  @include margin-less();
  @include set-min-width(min, mobile);
  height: 100%;
  font-family: Work Sans;

  * {
    font-family: Work Sans;
    box-sizing: border-box;
    @include set-color(textPrimary);
  }

  @include setup-svg-colors();
  @include setup-icons-sizes();
  @include setup-buttons-sizes();
  @include setup-color();
  @include setup-button-space();
  @include setup-hidden-all-screens();
  @include setup-space-all-settings(margin);
  @include setup-space-all-settings(padding);
  @include setup-text();
}