@use 'mixins' as *;

html {
  @include min-mobile {
    .mat-stepper-horizontal {
      &:not(.mat-stepper-label-position-bottom) .mat-horizontal-stepper-header {
        @include space((padding: (vertical: 0, horizontal: .75)));
      }

      &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        @include space(padding);
      }
    }
  }

  @include max-mobile {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
      @include space((padding: (vertical: 1, horizontal: .125)));
    }
  }


  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: var(--selected);
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--selected);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--selected);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  //--mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent
}

// html .mat-step-header.mat-warn {
//   --mat-stepper-header-icon-foreground-color: white;
//   --mat-stepper-header-selected-state-icon-background-color: #f44336;
//   --mat-stepper-header-selected-state-icon-foreground-color: white;
//   --mat-stepper-header-done-state-icon-background-color: #f44336;
//   --mat-stepper-header-done-state-icon-foreground-color: white;
//   --mat-stepper-header-edit-state-icon-background-color: #f44336;
//   --mat-stepper-header-edit-state-icon-foreground-color: white
// }

html {
  --mat-stepper-header-height: 3.5rem
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400
}